#plugtochat .call {
  height: 100%;
  position: relative;
}

#plugtochat .call--background {
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color-item);
}

#plugtochat .call--background--media {
  object-fit: cover;
  height: 100%;
  object-position: center;
  width: 100%;
}

#plugtochat .call--overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0.8029586834733894) 0%,
    rgba(0, 0, 0, 0) 24%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0.8) 100%
  );
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  justify-content: space-between;
}

#plugtochat .call--overlay--footer {
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 30px;
  /* gap: 20px; */
}

#plugtochat .call--overlay--footer--button {
  background-color: var(--bg-button-call);
  /* width: 60px;
        height: 60px; */
  border-radius: 50%;
  border: none;
  color: var(--text-color-white);
  font-size: 1.3rem;
  cursor: pointer;
  box-shadow: var(--image-shadow);
}

#plugtochat .call--overlay--footer--button--hang-up {
  background-color: var(--bg-button-hangUp);
  border-radius: 50%;
  padding: 10px 13px;
  background: red;
  line-height: 0pxx;
  display: flex;
  align-items: center;
}

#plugtochat .call--overlay--footer--actions {
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  gap: 20px;
}

#plugtochat .call--overlay--footer--yourself {
  width: 85px;
  height: 100px;
  justify-self: end;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 10px;
  box-shadow: var(--image-shadow);
}

#plugtochat .call--overlay--footer--yourself--media {
  object-fit: cover;
  height: 100%;
  object-position: center;
  width: 100%;
}

#plugtochat .call--overlay--header {
  padding: 30px;
}

#plugtochat .call--overlay--header--status {
  color: var(--text-color-white);
  font-size: 1.4rem;
}

#plugtochat .call--overlay--footer--button--answer {
  background-color: var(--icon-action-color);
}

#plugtochat .call--background--contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--text-color-white);
  margin-bottom: 40px;
}

#plugtochat .call--background--contact--image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: var(--image-shadow);
}

#plugtochat .call--background--contact .avator {
  width: 80px;
  height: 80px;
  font-size: 20px;
}
#plugtochat .call--background--contact .avatar > .avatar-text {
  font-size: 20px;
}
#plugtochat .outerContainer {
  position: fixed;
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 0;
  right: 0;
  z-index: 2147483639;
  transition: none 0s ease 0s !important;
}

#plugtochat h5 {
  font-size: 18px !important;
}

#plugtochat .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 20px;
  /* border: 1px solid #dee2e6; */
  /* color: #6c757d; */
  border: none;
  border-radius: 0;
  background: #fff;
  font-weight: 400;
  /* border-radius: 6px; */
  transition: box-shadow 0.2s;
  font-size: 14px;
  color: #000;
  margin: 0;
}

#plugtochat .p-accordion-header:hover {
  background-color: #fff !important;
}

#plugtochat .p-accordion-header {
  border-bottom: 1px solid #999;
}

#plugtochat .p-accordion .p-accordion-tab {
  margin: 0;
}

#plugtochat .p-accordion-content {
  background-color: #fbfbfb !important;
  padding: 2rem !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #999 !important;
  font-size: 14px;
}

#plugtochat .btn-s {
  width: 4rem;
  margin-left: 2rem;
  color: #fff !important;
  background-color: #267df4;
  height: 100%;
  border: none;
  text-decoration: none;
  font-size: 13px;
  line-height: 2.15384615;
  min-height: 30px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  -webkit-appearance: none;
  border-radius: 0.5rem;
  white-space: nowrap;
  box-sizing: border-box;
  outline: none;
  box-shadow: 0 1px 14px rgb(0 0 0 / 8%);
}

#plugtochat .invitation-button-container {
  flex-basis: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 6rem;
}

#plugtochat .invitation-button {
  width: 100%;
  height: 100%;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 9px 16px;
  text-decoration: none;
  color: #fff;
  border: 1px solid rgba(38, 39, 45, 0.1);
  box-shadow: 0 1px 14px rgb(0 0 0 / 8%);
  background-color: #267df4;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  font-family: inherit;
}

#plugtochat label {
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 4px;
}

#plugtochat
  .p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  box-shadow: none;
  outline: none;
}

#plugtochat
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background-color: #fff !important;
  text-decoration: none;
}

#plugtochat a {
  text-decoration: none !important;
}

#plugtochat .chat-ptc-qe {
  box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* position: absolute; */
  bottom: 26px;
  border-radius: 16px;
  pointer-events: auto;
  /* overflow: hidden; */
  /* z-index: 1; */
  right: 48px;
  left: auto;
  font-family: Mulish, sans-serif;
  letter-spacing: -0.24px;
  -webkit-font-smoothing: antialiased;
}

#plugtochat .chat-header-ptc-qe {
  color: rgb(255, 255, 255);
  background: linear-gradient(
    135deg,
    rgb(42, 39, 218) 0%,
    rgb(0, 204, 255) 100%
  );
  padding: 24px 28px 0px;
  position: relative;
  /* z-index: 4; */
  flex: 0 0 auto;
}

#plugtochat #conversation-group-ptc-qe {
  padding: 10px 28px;
  /* width: 100%; */
  height: 300px;
  overflow: hidden auto;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  max-height: 357px;
  min-height: 160px;
  flex: 0 1 auto;
}

#plugtochat #messages-ptc-qe {
  position: relative;
  margin-top: 10px;
  width: 100%;
  padding-bottom: 24px;
  float: left;
}

#plugtochat .history-button-wrapper-ptc-qe {
  clear: both;
  width: 100%;
  float: left;
}

#plugtochat .message-operator-ptc-qe {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  transition: transform 0.2s ease 0s, margin 0.2s ease 0s;
}

#plugtochat .message-ptc-qe {
  padding: 10px 16px;
  border-radius: 20px;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
}

#plugtochat .message-visitor-ptc-qe {
  color: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
  transition: transform 0.2s ease 0s, margin 0.2s ease 0s;
  padding: 10px 16px;
  border-radius: 20px;
  margin: 10px 0;
}

#plugtochat #conversation-group-ptc-qe #conversation-scroll-ptc-qe {
  width: 24px;
  height: calc(313px);
  position: absolute;
  right: 2px;
  padding: 0px 12px 0px 4px;
}

#plugtochat #conversation-group-ptc-qe #conversation-scroll-ptc-qe div {
  width: 6px;
  margin: 0px 1px;
  background: rgb(0, 23, 59);
  opacity: 0;
  position: absolute;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out 0s, width 0.1s ease-in-out 0s,
    margin 0.1s ease-in-out 0s;
  z-index: 2;
  user-select: none;
}

#plugtochat .chat-ptc-qe textarea {
  border: 0px;
  width: 100%;
  font-size: 17px;
  padding: 20px 0px 14px;
  resize: none;
  line-height: 24px;
  overflow-x: hidden;
}

#plugtochat #button.chat-open:not(.sidebar) {
  right: -20px;
  left: auto;
  z-index: 5;
  bottom: -6px;
}

#plugtochat #button {
  position: absolute;
  width: 112px;
  height: 140px;
  bottom: 12px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
}

#plugtochat #button:not(.sidebar) .buttonWave {
  position: absolute;
  z-index: -1;
  width: 60px;
  height: 60px;
}

#plugtochat #button-body {
  width: 60px;
  height: 60px;
  border-radius: 28px;
  display: inherit;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: initial;
  /* background-size: 130% 130%; */
  transition: all 0.2s ease-in-out 0s;
  position: relative;
  color: rgb(0, 125, 252);
  background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
  box-shadow: rgb(0 77 255 / 50%) 0px 4px 24px;
}

#plugtochat .offline-message {
  margin: 18px -28px 0px;
  color: currentcolor;
  /* width: calc(100% + 56px); */
  padding: 14px 28px 7px;
  position: relative;
  background-size: 100% calc(100% + 12px);
  z-index: 1;
  background-image: linear-gradient(
    135deg,
    rgba(42, 39, 218, 0.72) 0%,
    rgba(0, 204, 255, 0.72) 100%
  );
}

#plugtochat .no-clip-path .offline-message {
  padding: 14px 28px 20px;
}

#plugtochat .offline-message span {
  z-index: 2;
  position: relative;
  display: inline-block;
  font-size: 16px;
}

#plugtochat .offline-message span.online {
  padding-left: 20px;
}
#plugtochat .offline-message span.offline::before {
  background-color: #ddd !important;
}

#plugtochat .offline-message span.online::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 4px);
  background: rgb(88, 183, 67);
  border-radius: 50%;
  left: 0px;
}

#plugtochat .offline-message span {
  z-index: 2;
  position: relative;
  display: inline-block;
  font-size: 16px;
}

#plugtochat .no-clip-path .offline-message::after {
  content: "";
  position: absolute;
  /* width: calc(100% + 10px); */
  bottom: -8px;
  left: -5px;
  border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
  border-image-slice: 0 0 100%;
  border-image-width: 0 0 15px;
  border-image-repeat: stretch;
  border-width: 0px 0px 15px;
  border-bottom-style: solid;
  border-color: initial;
  border-top-style: initial;
  border-left-style: initial;
  border-right-style: initial;
}

#plugtochat .avatars-wrapper {
  width: 52px;
  height: 52px;
  margin: 0px 18px 0px 0px;
  float: left;
}

#plugtochat .header-ava {
  width: 52px;
  height: 52px;
  border-radius: 24px;
  background-size: cover;
  background-position: center center;
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlQBAwMDAwMDBAQEBAUFBQUFBwcGBgcHCwgJCAkICxELDAsLDAsRDxIPDg8SDxsVExMVGx8aGRofJiIiJjAtMD4+VP/CABEIADwAPAMBIgACEQEDEQH/xAAcAAEAAQUBAQAAAAAAAAAAAAAABgEDBAUHAgn/2gAIAQEAAAAA+lYAj8cy5ndHO8VNN0IFrk62gjEYudH9iLQPN6lec+0VmtG038L9UpWh/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAhAAAAAAAP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAAAD/xAAuEAABAwMACAUEAwAAAAAAAAABAgMEAAURBhASEyAhQVEiMmKRwSNxcoExQqH/2gAIAQEAAT8A14PHdbuuMvcMY2wPGs88Z6ClT5qzlUh0n8qjXmawoFay8jqlfwaZebkNIdbOUrGRwCpxJmySf53qtdgJNu+zq+G7N7u4yPUoK9xrsyC3bWfUVL9zw6QxiS1IA5Y2F/Gpplb7qGkeZasCkNpabQhPlQkJH64CMDJ5DvWkk+Gu3uRm3wp5ak42Dkp2TnJNC4S2hhyPvT0Wg4z9xVqmuN3SNJl/TabUcITzxkY2j3piRHkjLLrbg9Ks1gjVL0nnvLVuAllHTllf7Jp+VKknLz7jn5K+KAAGNY8JykkHuDg0xe7rG5JkqUB/VY2x/tR9LkBvEmMsud2/KfeumrtXSu9dKFZNf//EABQRAQAAAAAAAAAAAAAAAAAAAED/2gAIAQIBAT8AB//EABQRAQAAAAAAAAAAAAAAAAAAAED/2gAIAQMBAT8AB//Z);
  float: left;
}

#plugtochat .chat-ptc-qe h2 {
  font-size: 22px;
  font-weight: 600;
  color: currentcolor;
  margin: 4px 0px 0px;
  padding: 0px;
  display: inline-block;
  position: relative;
  /* max-width: calc(100% - 145px); */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}

#plugtochat .chat-ptc-qe h2.twolines {
  margin-bottom: 5px;
}

#plugtochat button.exit-chat,
#plugtochat label.exit-chat {
  margin-right: -3px;
  color: rgb(255, 255, 255);
}

#plugtochat button.material-icons::before,
#plugtochat label.material-icons::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.16s ease-in-out 0s;
  transform: scale(0);
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

#plugtochat .chat-ptc-qe hr {
  margin: 0px;
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-bottom-color: rgb(219, 223, 230);
}

#plugtochat .chat-ptc-qe textarea:focus {
  outline: none;
  border: none;
}

#plugtochat .chat-ptc-qe .input-group {
  padding: 0px 28px 6px;
  position: relative;
  background: rgb(255, 255, 255);
  z-index: 3;
  flex: 0 0 auto;
}

#plugtochat .input-group .footer-bottom {
  height: 42px;
  position: relative;
}

#plugtochat .input-group .footer-bottom button {
  float: left;
  margin: 0px 16px 14px 0px;
}

#plugtochat .form-handler {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}

#plugtochat .form-label {
  color: #12263f;
  font-weight: 400;
  font-size: 0.875rem;
}

#plugtochat .form-control {
  margin-top: 8px;
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  color: #12263f;
  display: block;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 300px;
}

#plugtochat .text-danger {
  color: red;
}

#plugtochat .form-group {
  margin-bottom: 14px;
  width: 100%;
}

#plugtochat .btn-primary {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}

#plugtochat .btn {
  border: 1px solid transparent;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}

#plugtochat .mb-3 {
  margin-bottom: 0.75rem !important;
}

#plugtochat .emoji-mart-search {
  display: none;
}

#plugtochat .app-icon {
  padding: 20px 20px 13px;
  /* background-color: rgb(0, 108, 255); */
  /* border-radius: 100%; */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  margin: 0 !important;
}

#plugtochat .app-icon img {
  width: 60px;
  height: 60px;
}

#plugtochat .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 15px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  width: 370px;
  height: 60%;
  margin: 30px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 25%);
}
#plugtochat .outerContainer-chat {
  width: 370px;
}

@media (max-width: 376px) {
  #plugtochat .outerContainer-chat {
    width: 320px !important;
  }
}

@media (min-width: 376px) and (max-width: 400px) {
  #plugtochat .outerContainer-chat {
    width: 350px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #plugtochat .form-control {
    width:  calc(100% - 25px) !important;
  }
  #plugtochat .outerContainer-chat {
    border-radius: 15px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    margin: 20px;
    max-height: calc(100% - 40px);
    max-width: calc(100% - 40px);
    overflow: hidden;
  }
  #plugtochat .outerContainer {
    justify-content: center !important;
    flex-direction: row !important;
  }
  #plugtochat #button.chat-open:not(.sidebar) {
    right: -24px !important;
  }
  #plugtochat .emoji-mart {
    left: -73px !important;
  }
  #plugtochat .container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  #plugtochat .container {
    width: 60%;
  }
}

#plugtochat .call-dialog {
  height: 357px;
  width: 100%;
}

#plugtochat .call-dialog {
  max-height: 100% !important;
}

#plugtochat .call-dialog .p-dialog-header {
  display: none !important;
}

#plugtochat .call-dialog .call {
  padding: 0 !important;
  background-color: #666 !important;
}

#plugtochat .avatar {
  height: 60px;
  width: 60px;
}

#plugtochat .avatar,
#plugtochat .avatar .avatar-img,
#plugtochat .avatar .avatar-text {
  border-radius: 50%;
}

#plugtochat .avatar .avatar-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 16px !important;
}

#plugtochat .avatar {
  position: relative;
  display: flex;
}

#plugtochat .call--background--contact--image {
  width: auto !important;
  height: auto !important;
}

#plugtochat .typing {
  height: 50px;
  width: 100%;
  border-top: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#plugtochat .typing input {
  font-size: 16px;
  line-height: 20px;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  padding: 0 20px;
  background: transparent;
  border: 0;
}

#plugtochat .typing input:focus {
  outline: none;
  box-shadow: no;
}

#plugtochat .typing .send-icon {
  padding-right: 20px;
  width: 26px;
  height: 26px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26px;
  flex: 0 0 26px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

#plugtochat .infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2979ff;
  border-radius: 4px 4px 0 0;
  height: 60px;
  width: 100%;
}

#plugtochat .leftInnerContainer {
  flex: 0.5;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

#plugtochat .rightInnerContainer {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  margin-right: 5%;
}

#plugtochat .onlineIcon {
  margin-right: 5%;
}

#plugtochat .messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

#plugtochat .messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

#plugtochat .messageText img {
  vertical-align: middle;
}

#plugtochat .messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

#plugtochat .sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

#plugtochat .pl-10 {
  padding-left: 10px;
}

#plugtochat .pr-10 {
  padding-right: 10px;
}

#plugtochat .justifyStart {
  justify-content: flex-start;
}

#plugtochat .justifyEnd {
  justify-content: flex-end;
}

#plugtochat .colorWhite {
  color: white;
}

#plugtochat .colorDark {
  color: #353535;
}

#plugtochat .backgroundBlue {
  background: #2979ff;
}

#plugtochat .backgroundLight {
  background: #f3f3f3;
}

#plugtochat .messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
  background-color: rgb(234, 238, 243);
}
